import React from 'react';
import { Badge } from 'react-bootstrap';

import Tooltip from 'react-tooltip-lite';
import BookIcon from '@material-ui/icons/Book';

const bootstrapColor = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];

export function getRandomIntInclusive(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateTags(key, tagsList) {
   var styles = {
      padding: '0.1em 0 0.15em 0.4em',
      float: 'right',
      color: '#1e2e62',
      fontSize: 16
   };

   let index = 0;
   return (
      <div id="tag">
         <ul className="">
            {tagsList.map(t => {
               if (t !== '@readonly') {
                  index += 1;
                  return (
                     <>
                        <li key={`${key}-badge-${index}-1`}>
                           {t !== '@argomento' ? (
                              <Badge variant={bootstrapColor[1]}>{t}</Badge>
                           ) : (
                              <Badge style={styles}>
                                 <Tooltip content="@argomento" className="target">
                                    <BookIcon />
                                 </Tooltip>
                              </Badge>
                           )}
                        </li>
                     </>
                  );
               } else return null;
            })}
         </ul>
      </div>
   );
}
