/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import { Alert } from '@material-ui/lab';
import Tooltip from 'react-tooltip-lite';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const APP_JSON = 'application/json';
//const loadingMessage = 'Caricamento preview...';
const scoreElements = [
   { value: 10, label: 'OTTIMO', aria: 'OTTIMO' },
   { value: 8, label: 'BUONO', aria: 'BUONO' },
   { value: 6, label: 'SUFFICIENTE', aria: 'SUFFICIENTE' },
   { value: 4, label: 'INSUFFICIENTE', aria: 'INSUFFICIENTE' }
];

export default class SelfEvaluation extends Component {
   constructor(props) {
      super(props);
      this.state = {
         score: 0,
         rating: null,
         comment: '',
         saved: false
      };
      this.postData = this.postData.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
   }

   componentDidUpdate(prevProps) {
      if (this.props.elementID !== prevProps.elementID) {
         const { username, elementID, server } = this.props;
         (async () => {
            const response = await fetch(`${server}/api/user_doc/find`, {
               method: 'POST',
               credentials: 'include',
               headers: { 'Content-Type': APP_JSON },
               body: JSON.stringify({ username, elementID })
            });
            const result = await response.json();
            if (response.ok) {
               if (result)
                  this.setState({
                     score: result.score ? result.score : null,
                     rating: result.rating ? result.rating : null,
                     comment: result.comment ? result.comment : ''
                  });
               else this.setState({ score: null, rating: null, comment: '' });
            } else {
               console.log('Fetch Error!', response, result);
            }
         })();
      }
   }
   componentDidMount() {
      const { username, elementID, server } = this.props;
      if (!username || !elementID) return;
      (async () => {
         const response = await fetch(`${server}/api/user_doc/find`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': APP_JSON },
            body: JSON.stringify({ username, elementID })
         });
         const result = await response.json();
         if (response.ok) {
            if (result)
               this.setState({
                  score: result.score ? result.score : null,
                  rating: result.rating ? result.rating : null,
                  comment: result.comment ? result.comment : ''
               });
            else this.setState({ score: null, rating: null, comment: '' });
         } else {
            console.log('Fetch Error!', response, result);
         }
      })();
   }

   handleInputChange(event, newValue) {
      const { target } = event;
      const { name } = target;

      let value;
      switch (name) {
         case 'rating':
            value = parseInt(newValue);
            break;
         case 'score':
            value = newValue;
            break;
         default:
            value = target.type === 'checkbox' ? target.checked : target.value;
            break;
      }

      this.setState({
         [name]: name === 'rating' ? parseInt(value) : value
      });
   }

   postData(event, value) {
      const { username, elementID, nodeInfo, elementPath, server } = this.props;
      if (!username || !elementID) return;
      if (event) event.preventDefault();
      (async () => {
         let score = value !== 'scoreReset' && value !== 'formReset' ? value : this.state.score;
         let rating = this.state.rating;
         let comment = this.state.comment;
         if (value === 'scoreReset') {
            score = 0;
            this.setState({ score });
         }
         if (value === 'formReset') {
            rating = null;
            comment = '';
            this.setState({ rating, comment });
         }
         const model = {
            username,
            elementID,
            score,
            rating,
            comment,
            elementPath,
            name: nodeInfo.name,
            docRev: nodeInfo?.properties['cm:versionLabel'] ?? 0
         };
         console.log('postData : ', model);

         const response = await fetch(`${server}/api/user_doc/upsert`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': APP_JSON },
            body: JSON.stringify(model)
         });

         const result = await response.json();
         this.setState({ saved: true }, () => {
            this.props.refresh();
            setTimeout(() => this.setState({ saved: false }), 2500);
         });
      })();
   }

   render() {
      const { saved, score } = this.state;
      return (
         <>
            <Row className="row-flex">
               <Col md={6} className="md-pr-5">
                  <Card className="kt-section__content col-height">
                     <Card.Header as="h4">Self-evaluation</Card.Header>
                     <Card.Body>
                        {/*<Card.Subtitle className="mb-2 text-muted">Hai letto e compreso il documento?</Card.Subtitle>*/}
                        <Card.Text>
                           Si prega di indicare qui di seguito il tuo grado di comprensione. La valutazione espressa ci
                           aiuterà a capire se e in quale misura il documento ha risposto ai tuoi bisogni formativi
                        </Card.Text>

                        <div className="MuiToggleButton-label">
                           {saved ? (
                              <Alert
                                 className="alert-saved"
                                 iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}
                              >
                                 Salvato!
                              </Alert>
                           ) : null}

                           <ToggleButtonGroup exclusive value={this.state.score}>
                              {scoreElements.map((element, index) => (
                                 <ToggleButton
                                    key={`scoreElement-${index}`}
                                    name="score"
                                    value={element.value}
                                    aria-label={element.aria}
                                    //disabled={documentURL === '/'}
                                    selected={this.state.score === element.value}
                                    onClick={(e, value) => {
                                       this.setState({ score: value }, this.postData(e, value));
                                    }}
                                 >
                                    {element.value === 1 ? (
                                       <Tooltip
                                          content="La valutazione della qualità del documento è obbligatoria se la comprensione del documento è NULLA"
                                          className="target tooltip-xl"
                                          tipContentClassName=""
                                       >
                                          {element.label}
                                       </Tooltip>
                                    ) : (
                                       <>{element.label}</>
                                    )}
                                 </ToggleButton>
                              ))}
                           </ToggleButtonGroup>
                           <Button
                              variant="contained"
                              color="default"
                              size="small"
                              className="right eval"
                              startIcon={<CachedIcon />}
                              aria-label="RESET"
                              value="reset"
                              onClick={(e, value) => this.postData(e, 'scoreReset')}
                           >
                              RESET
                           </Button>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
               <Col md={6} className="md-pl-5">
                  <Card className="kt-section__content col-height">
                     <Card.Header as="h4">Qualità materiale</Card.Header>
                     <Card.Body>
                        <Card.Text>Nel complesso come valuti questo documento?</Card.Text>
                        <Form>
                           <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Box component="fieldset" mb={3} borderColor="transparent">
                                 {/*<Typography component="legend">Lorem ipsum</Typography>*/}
                                 <Rating
                                    //disabled={documentURL === '/'}
                                    name="rating"
                                    value={this.state.rating}
                                    onChange={this.handleInputChange}
                                 />
                              </Box>
                              <Form.Label>Hai osservazioni o suggerimenti?</Form.Label>
                              <Form.Control
                                 as="textarea"
                                 rows="3"
                                 maxLength="255"
                                 onChange={this.handleInputChange}
                                 name="comment"
                                 value={this.state.comment}
                              />
                           </Form.Group>
                           <Button
                              //disabled={documentURL === '/'}
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={this.postData}
                           >
                              Conferma
                           </Button>
                           <Button
                              variant="contained"
                              color="default"
                              size="small"
                              className="right"
                              startIcon={<CachedIcon />}
                              aria-label="RESET"
                              name="form"
                              value="reset"
                              onClick={(e, value) => this.postData(e, 'formReset')}

                              //disabled={documentURL === '/'}
                           >
                              RESET
                           </Button>
                           {saved && origin === 'qualita' ? (
                              <Alert
                                 className="alert-saved"
                                 iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}
                              >
                                 Salvato!
                              </Alert>
                           ) : null}
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </>
      );
   }
}
