import * as Todo from '../Todo';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';

/**
 * Mappatura route
 * La route avente path='/' è la root sulla quale l'utente viene direzionato dopo la login
 *
 */

export default [
   { path: '/home', label: 'Home', exact: true, component: Todo.Home, visible: false, index: 0 },
   { path: '/', label: 'Dashboard', exact: true, component: Dashboard, visible: true, index: 1 },
   { path: '/about', label: 'About', exact: true, component: Todo.About, visible: false, index: 2 },
   // Questi componenti non sono visibili all'utente ma devono rimanere qui
   { path: '/login', label: 'Login', exact: true, component: Login, visible: false, index: 998 },
   { path: '*', label: 'Not Found', exact: false, component: Todo.NoMatch, visible: false, index: 999 }
];
