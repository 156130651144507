import React from 'react';
import Tooltip from 'react-tooltip-lite';
import WarningIcon from '@material-ui/icons/Warning';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';

const Icon = props => {
   let comp = '';
   let mess = '';
   if (props.stato === 0) {
      comp = <ErrorIcon className="iconStato error" />;
      mess = 'Documento da valutare';
   } else if (props.stato === 1) {
      comp = <WarningIcon className="iconStato warning" />;
      mess = 'Attenzione, il documento è stato modificato';
   } else if (props.stato === 2) {
      comp = <CheckBoxIcon className="iconStato success" />;
      mess = 'Questo documento è stato valutato correttamente';
   }

   return (
      <Tooltip content={mess} className="target" tipContentClassName="">
         {comp}
      </Tooltip>
   );
};

export default Icon;
