// React
import React, { useState } from 'react';

// Style
import './Dropdown.css';

// Components
import { Dropdown } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';
// import PasswordReset from './ModalPassword';

export default function Dropdowns(props) {
   const [showResetPassword, setShowResetPassword] = useState(false);
   const { username, handleLogouts } = props;
   return (
      <>
         <Dropdown>
            <Dropdown.Toggle
               variant="default"
               alignright="true"
               drop="right"
               size="sm"
               id="dropdown-basic"
               alt={username.toUpperCase()}
               src="/static/media/avatar/1.jpg"
            >
               <Avatar alt={username.toUpperCase()} src="/static/media/avatar/1.jpg" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
               <Dropdown.Item disabled>{username}</Dropdown.Item>
               {/* <Dropdown.Item onClick={() => setShowResetPassword(!showResetPassword)}>Reset Password</Dropdown.Item> */}
               <Dropdown.Divider />
               <Dropdown.Item onClick={handleLogouts}>Logout</Dropdown.Item>
            </Dropdown.Menu>
         </Dropdown>
         {/*<PasswordReset show={showResetPassword} onHide={() => setShowResetPassword(!showResetPassword)} /> */}
      </>
   );
}
