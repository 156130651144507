// FIXME:
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const UnsupportedViewer = props => (
   <div className="pg-driver-view">
      <div className="unsupported-message">
         {props.unsupportedComponent ? (
            <props.unsupportedComponent {...props} />
         ) : (
            <Alert severity="warning" variant="outlined" className="mt-2">
               <AlertTitle>Anteprima non disponibile</AlertTitle>
               Non è stato possibile caricare l'anteprima del documento selezionato
            </Alert>
         )}
      </div>
   </div>
);

export default UnsupportedViewer;
