// React
import React from 'react';
// Router
import { Link } from 'react-router-dom';
// Components
import { Navbar } from 'react-bootstrap';
// Style
import './Navbar.css';
// img
import logo from '../../assets/logo-ebs.png';

import Dropdown from './Dropdown';
import routes from '../core/Routes';

export default function FNavbar(props) {
   const { isAuthenticated, username, handleLogout } = props;

   return (
      <Navbar bg="ligth" variant="ligth" collapseOnSelect expand="lg">
         <Navbar.Brand>
            <img src={logo} alt="EBS Consulting" />
         </Navbar.Brand>
         {isAuthenticated ? (
            <>
               <Navbar.Toggle aria-controls="responsive-navbar-nav" />

               <Navbar.Collapse id="responsive-navbar-nav" className="kt_header_menu">
                  <ul className="kt-menu__nav mr-auto">
                     {routes.map(route =>
                        route.visible ? (
                           <li key={`li-${route.index}`} className="kt-menu__item">
                              <Link key={`link-${route.index}`} exact={route.exact ? 'true' : 'false'} to={route.path}>
                                 {route.label}
                              </Link>
                           </li>
                        ) : null
                     )}
                  </ul>
                  {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                  <h5 className="jsflgr">{''}</h5>
                  <Dropdown username={username} handleLogouts={handleLogout} />
               </Navbar.Collapse>
            </>
         ) : null}
      </Navbar>
   );
}
