/* eslint-disable react/jsx-props-no-spreading */
// React
import React, { Component } from 'react';
// Router
import { Switch, Route, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
// Style
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Components
import { Alert, Container, Row, Col } from 'react-bootstrap';
import Login from '../Login/Login';
import PrivateRoute from '../core/PrivateRoute';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import routes from '../core/Routes';

/*
  Tree Menu
    https://reactjsexample.com/a-simple-react-tree-menu-component/
    https://github.com/iannbing/react-simple-tree-menu

  Routing
    https://reacttraining.com/react-router/web/example/basic

*/

const APP_JSON = 'application/json';
// Application
class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         username: props.username,
         sessionID: props.sessionID,
         personNameSurname: props.personNameSurname,
         isAuthenticated: props.isAuthenticated,
         showLoginMessage: props.isAuthenticated,
         invalidatedDocs: 0
      };
      // Function to handle timed-alert
      this.interval = null;
      // Bind functions to specific instance
      this.handleLogin = this.handleLogin.bind(this);
      this.handleLogout = this.handleLogout.bind(this);
      this.onAlertClose = this.onAlertClose.bind(this);
   }

   async componentDidMount() {
      const { username, server } = this.props;
      const { isAuthenticated } = this.state;
      // Fetch document
      if (isAuthenticated) {
         this.interval = setTimeout(() => this.setState({ showLoginMessage: false }), 7000);
         try {
            const response = await fetch(`${server}/api/docs/invalidUserDocs`, {
               method: 'POST',
               credentials: 'include',
               headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
               body: JSON.stringify({ username })
            });
            const result = await response.json();
            if (response.ok && result > 0) {
               this.setState({ invalidatedDocs: result });
            }
         } catch (error) {
            console.log(error);
         }
      }
   }

   onAlertClose() {
      this.setState({ showLoginMessage: false });
   }

   handleLogin(username, personNameSurname, sessionID, invalidatedDocs) {
      this.interval = setTimeout(() => this.setState({ showLoginMessage: false }), 7000);
      this.setState({
         username,
         personNameSurname,
         sessionID,
         isAuthenticated: true,
         showLoginMessage: true,
         invalidatedDocs
      });
   }

   handleLogout() {
      (async () => {
         const response = await fetch(`${this.props.server}/api/alfresco/auth/session`, {
            method: 'DELETE',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            credentials: 'include',
            body: JSON.stringify({ sessionID: this.state.sessionID })
         });
         const result = await response.json();
         if (response.ok) {
            this.setState({ sessionID: '', isAuthenticated: false, showLoginMessage: false });
            const cookies = new Cookies();
            cookies.remove('sessionID');
         } else {
            console.log('Logout failed! : ', result?.message);
         }
      })();
   }

   render() {
      // State / props
      const { location, server, role } = this.props;
      const { username, personNameSurname, sessionID, isAuthenticated, showLoginMessage, invalidatedDocs } = this.state;
      // Reperimento route attuale
      const routeElement = routes.find(route => route.path === location.pathname);
      // Messaggio di login
      let loginMessage = '';
      if (parseInt(invalidatedDocs) > 1) {
         loginMessage = `Benvenuto ${personNameSurname}, hai ${invalidatedDocs} documenti da revisionare poichè soggetti a versionamento . `;
      } else if (parseInt(invalidatedDocs) === 1) {
         loginMessage = `Benvenuto ${personNameSurname}, hai ${invalidatedDocs} documento da revisionare poichè soggetto a versionamento . `;
      } else {
         loginMessage = `Benvenuto ${personNameSurname}`;
      }

      return (
         <div className="App">
            <ThemeProvider theme={theme}>
               <Container>
                  {// Mostro navbar senza sfondo per la login page
                  !isAuthenticated ? (
                     <header className="App-header ">
                        <Navbar
                           isAuthenticated={isAuthenticated}
                           username={username}
                           handleLogout={this.handleLogout}
                        />
                     </header>
                  ) : (
                     <header className="App-header bg-white">
                        <Navbar
                           isAuthenticated={isAuthenticated}
                           username={username}
                           handleLogout={this.handleLogout}
                        />
                     </header>
                  )}

                  {isAuthenticated ? (
                     <Row>
                        <Col>
                           <div className="kt-container  kt-container--fluid ">
                              <div className="kt-subheader__main">
                                 {/* Titolo pagina */}
                                 <h3 className="kt-subheader__title">
                                    {routeElement ? routeElement.label : 'Not Found'}
                                 </h3>
                              </div>
                           </div>
                           {/* Messaggio di benvenuto */
                           showLoginMessage ? (
                              <Alert variant="success" dismissible onClose={this.onAlertClose}>
                                 <b>{loginMessage}</b>
                              </Alert>
                           ) : null}
                        </Col>
                     </Row>
                  ) : null}

                  <Switch>
                     {/*
                        L'unica route pubblica è la login, quindi la gestisco separatamente
                        I dati di accesso vengono salvati tramite localStorage. ( vedi componentDidMount )
                        Se precedentemente ho gia fatto la login, vado direttamente alla Home
                      */
                     !isAuthenticated ? (
                        <Route exact path="/login" handleLogin={this.handleLogin}>
                           <Login handleLogin={this.handleLogin} server={server} role={role} />
                        </Route>
                     ) : null}

                     {/* Carica dinamicamente le route a partire dall'oggetto routes ( vedi sopra ) */
                     routes.map(route => (
                        <PrivateRoute
                           key={route.index}
                           path={route.path}
                           exact={route.exact}
                           server={server}
                           isAuthenticated={isAuthenticated}
                        >
                           <route.component
                              username={username}
                              personNameSurname={personNameSurname}
                              sessionID={sessionID}
                              server={server}
                              role={role}
                              invalidatedDocs={invalidatedDocs}
                           />
                        </PrivateRoute>
                     ))}
                  </Switch>

                  <Row className="justify-content-md-center">
                     <Col>
                        <Footer />
                     </Col>
                  </Row>
               </Container>
            </ThemeProvider>
         </div>
      );
   }
}

const Application = withRouter(App);
export default Application;

// Theme
const theme = createMuiTheme({
   palette: {
      common: { black: '#000', white: '#fff' },
      background: { paper: '#fff', default: '#fafafa' },
      primary: {
         light: 'rgba(121, 135, 176, 1)',
         main: 'rgba(30, 46, 98, 1)',
         dark: 'rgba(14, 26, 65, 1)',
         contrastText: '#fff'
      },
      secondary: {
         light: 'rgba(241, 141, 75, 1)',
         main: 'rgba(234, 98, 7, 1)',
         dark: 'rgba(211, 66, 0, 1)',
         contrastText: '#fff'
      },
      error: {
         light: 'rgba(246, 109, 109, 1)',
         main: 'rgba(242, 15, 0, 1)',
         dark: 'rgba(172, 0, 0, 1)',
         contrastText: '#fff'
      },
      text: {
         primary: 'rgba(68, 68, 68, 1)',
         secondary: 'rgba(0, 0, 0, 0.54)',
         disabled: 'rgba(0, 0, 0, 0.38)',
         hint: 'rgba(0, 0, 0, 0.38)'
      }
   }
});
