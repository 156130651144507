// FIXME:
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { VideoViewer, PDFViewer, UnsupportedViewer, PhotoViewerWrapper, AudioViewer } from './drivers';

export default class FileViewer extends Component {
   constructor(props) {
      super(props);
      this.state = { loading: true };
   }

   componentDidMount() {
      const container = document.getElementById('pg-viewer');
      const height = container ? container.clientHeight : 0;
      const width = container ? container.clientWidth : 0;
      this.setState({ height, width });
   }

   getDriver() {
      const { fileType } = this.props;
      switch (fileType) {
         /* case 'csv': {
            return withFetching(CsvViewer, this.props);
         }
         case 'xlsx': {
            const newProps = { ...this.props, responseType: 'arraybuffer' };
            return withFetching(XlsxViewer, newProps);
         } */
         case 'jpg':
         case 'jpeg':
         case 'gif':
         case 'bmp':
         case 'png': {
            return PhotoViewerWrapper;
         }
         case 'pdf': {
            return PDFViewer;
         }
         /* case 'docx': {
            return DocxViewer;
         } */
         case 'mp3': {
            return AudioViewer;
         }
         case 'webm':
         case 'mp4': {
            return VideoViewer;
         }
         default: {
            return UnsupportedViewer;
         }
      }
   }

   render() {
      const { width, height } = this.props;
      const Driver = this.getDriver(this.props);
      return (
         <div className="pg-viewer-wrapper">
            <div className="pg-viewer" id="pg-viewer">
               <Driver {...this.props} width={width} height={height} />
            </div>
         </div>
      );
   }
}

FileViewer.propTypes = {
   fileType: PropTypes.string.isRequired,
   filePath: PropTypes.string.isRequired,
   onError: PropTypes.func,
   errorComponent: PropTypes.element,
   unsupportedComponent: PropTypes.element
};

FileViewer.defaultProps = {
   onError: () => null,
   errorComponent: null,
   unsupportedComponent: null
};
