/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
// TreeMenu
import Icon from './Icon';
import DocumentInfo from './DocumentInfo';
import SelfEvaluation from './SelfEvaluation';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import { Row, Col, InputGroup, Button, FormControl, Dropdown, DropdownButton } from 'react-bootstrap';
import SearchIcon from '@material-ui/icons/Search';
// Style
import './Dashboard.css';
import './SelfEvaluation.css';
import { generateTags } from '../core/helper';

const searchTypeEnum = { FILE_NAME: 'filename', TAG: 'tag' };

// https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
const APP_JSON = 'application/json';
export default class Dashboard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: null,
         currentElementID: null,
         currentElementPath: '',
         nodeInfo: null,
         isFolderArg: false,
         parentIsFolderArg: false,
         searchType: searchTypeEnum.FILE_NAME,
         needRefresh: false,
         loading: false
      };
      this.loaded = this.loaded.bind(this);
      this.refresh = this.refresh.bind(this);
      this.treeSearch = this.treeSearch.bind(this);
      this.onClickItem = this.onClickItem.bind(this);
      this.onSelectSearch = this.onSelectSearch.bind(this);
   }

   async componentDidMount() {
      this.fetchNodes();
   }
   async refresh() {
      this.fetchNodes();
   }

   async fetchNodes() {
      const { server } = this.props;
      const documentRoot = 'shared';
      try {
         const response = await fetch(`${server}/api/alfresco/nodes/node/list`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ documentRoot, isManApp: false })
         });
         const result = await response.json();
         this.setState({ data: result });
      } catch (error) {
         console.log(error);
      }
   }

   loaded() {
      this.setState({ loading: false });
   }
   onSelectSearch(eventKey, event, resetOpenNodes) {
      console.log('onSelectSearch : ', this.state.searchType, searchTypeEnum[eventKey]);
      resetOpenNodes();
      this.formControl.value = '';
      this.setState({ searchType: searchTypeEnum[eventKey] });
   }

   // eslint-disable-next-line class-methods-use-this
   onClickItem(element) {
      if (!element) return;

      if (element.isFolder && !element.tags.includes('@argomento')) {
         this.setState({
            currentElementID: null,
            currentElementPath: '',
            nodeInfo: null
         });
         return;
      }

      const currentElementID = element.key.substring(element.key.lastIndexOf('/') + 1);
      const parentElementID = element.parent.substring(element.parent.lastIndexOf('/') + 1);
      const isFolderArg = element.tags.includes('@argomento');
      //const parentIsFolderArg = (element.parent).tags.includes("@argomento");
      const parentIsFolderArg = element.tags.includes('@readonly');
      const { server } = this.props;
      let nodeInfo = null;

      (async () => {
         // Get Node Info
         try {
            const response = await fetch(`${server}/api/alfresco/nodes/node`, {
               method: 'POST',
               credentials: 'include',
               headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
               body: JSON.stringify({ elementID: currentElementID })
            });
            nodeInfo = await response.json();
         } catch (error) {
            console.error('Cannot get node info', error);
         }

         // Get Node content and update children if it's a File

         if ((nodeInfo && nodeInfo.isFile) || (nodeInfo.isFolder && isFolderArg == true)) {
            console.log('current e parent element -------> ', currentElementID, parentElementID);
            this.setState({
               currentElementID,
               currentElementPath: element.path,
               nodeInfo,
               isFolderArg: isFolderArg,
               parentIsFolderArg: parentIsFolderArg,
               loading: true
            });
         }
      })();
   }

   treeSearch({ searchTerm, label, tags, isFolder }) {
      // Escludo cartelle dalla ricerca
      if (isFolder == true && !tags.includes('@argomento')) return false;
      // Ricerco confrontando il toLowerCase()

      switch (this.state.searchType) {
         case searchTypeEnum.FILE_NAME:
            return label.toLowerCase().includes(searchTerm.toLowerCase());
         case searchTypeEnum.TAG:
            // Se non ci sono tags evito di cercare inutilmente
            if (tags.length === 0) return false;
            //return tags.find(e => e.toLowerCase().includes(searchTerm.toLowerCase())) ? true : false;
            let tagsArrayWoutSysTags = tags.filter(tag => !tag.includes('@'));
            return tagsArrayWoutSysTags.find(e => e.toLowerCase().includes(searchTerm.toLowerCase())) ? true : false;
         default:
            return false;
      }
   }

   render() {
      const {
         data,
         currentElementID,
         currentElementPath,
         nodeInfo,
         searchType,
         needRefresh,
         loading,
         parentIsFolderArg
      } = this.state;

      // Tipi di ricerca disponibili ( ricordarsi di implementare anche la ricerca )
      let searchTypeElement = [];
      for (const key in searchTypeEnum)
         searchTypeElement.push(
            <Dropdown.Item key={`dropdown-item-${key}`} eventKey={key}>
               {searchTypeEnum[key]}
            </Dropdown.Item>
         );

      console.log('currentElementID : ', currentElementID);
      console.log('nodeInfo : ', nodeInfo);
      return (
         <>
            <Row>
               <Col md={6} xl={7} className="md-pr-5">
                  <div className="kt-section__content kt-aside">
                     <TreeMenu
                        data={data}
                        debounceTime={1000}
                        onClickItem={this.onClickItem}
                        matchSearch={this.treeSearch}
                        cacheSearch={false}
                     >
                        {({ search, items, /*searchTerm,*/ resetOpenNodes }) => (
                           <>
                              <InputGroup className="mb-3">
                                 <InputGroup.Prepend>
                                    <Button onClick={resetOpenNodes}>Comprimi</Button>
                                    <DropdownButton
                                       variant="outline-secondary"
                                       title={searchType}
                                       id="searchTypeDropDown"
                                       onSelect={this.onSelectSearch}
                                       onSelect={(eventKey, event) =>
                                          this.onSelectSearch(eventKey, event, resetOpenNodes)
                                       }
                                    >
                                       {searchTypeElement}
                                    </DropdownButton>
                                 </InputGroup.Prepend>

                                 <div className="has-search custom-file">
                                    <SearchIcon className="form-control-feedback" />
                                    <FormControl
                                       onChange={e => search(e.target.value)}
                                       aria-describedby="basic-addon1"
                                       placeholder="Search documents"
                                       ref={elementReference => (this.formControl = elementReference)}
                                    />
                                 </div>
                              </InputGroup>
                              <ul>
                                 {items.map(({ key, ...props }) => {
                                    return (
                                       <div key={`outer-${key}`}>
                                          <div
                                             className={
                                                props.tags.length > 0 ? 'containItem containItem-tag ' : 'containItem '
                                             }
                                          >
                                             <ItemComponent {...props} key={`${key}-item`} disabled={loading} />
                                             {!props.tags.includes('@readonly') ? (
                                                <Icon
                                                   stato={props.stato}
                                                   key={`${key}-icon`}
                                                   needRefresh={needRefresh}
                                                />
                                             ) : null}
                                          </div>
                                          {props.tags.length > 0 ? generateTags(key, props.tags) : null}
                                       </div>
                                    );
                                 })}
                              </ul>
                           </>
                        )}
                     </TreeMenu>
                  </div>
               </Col>
               <Col md={6} xl={5} className="md-pl-5">
                  {currentElementID && nodeInfo.isFile ? (
                     <DocumentInfo
                        elementID={currentElementID}
                        elementPath={currentElementPath}
                        nodeInfo={nodeInfo}
                        refresh={this.refresh}
                        {...this.props}
                     />
                  ) : null}
               </Col>
            </Row>

            {currentElementID && !parentIsFolderArg ? (
               <SelfEvaluation
                  elementID={currentElementID}
                  elementPath={currentElementPath}
                  nodeInfo={nodeInfo}
                  refresh={this.refresh}
                  loaded={this.loaded}
                  {...this.props}
               />
            ) : null}
         </>
      );
   }
}
