import React, { Component } from 'react';

export default class PhotoViewer extends Component {
   componentDidMount() {
      const { originalWidth, originalHeight, texture } = this.props;
      const imageDimensions = this.getImageDimensions.call(this, originalWidth, originalHeight);

      texture.image.style.width = imageDimensions.width !== 0 ? `${imageDimensions.width}px` : '30vw';
      texture.image.style.height = imageDimensions.height !== 0 ? `${imageDimensions.height}px` : '30vw';
      console.log(`PhotoViewer : width '${imageDimensions.width}' , height '${imageDimensions.height}'`);
      console.log(`PhotoViewer : width '${texture.image.style.width}' , height '${texture.image.style.height}'`);
      texture.image.style.width = '100%';
      texture.image.style.heigth = 'auto';
      texture.image.setAttribute('class', 'photo');
      document.getElementById('pg-photo-container').appendChild(texture.image);
   }

   getImageDimensions(originalWidth, originalHeight) {
      // Scale image to fit into viewer
      let imgHeight;
      let imgWidth;
      const { height: viewerHeight, width: viewerWidth } = this.props;

      if (originalHeight <= viewerHeight && originalWidth <= viewerWidth) {
         imgWidth = originalWidth;
         imgHeight = originalHeight;
      } else {
         const heightRatio = viewerHeight / originalHeight;
         const widthRatio = viewerWidth / originalWidth;
         if (heightRatio < widthRatio) {
            imgHeight = originalHeight * heightRatio;
            imgWidth = originalWidth * heightRatio;
         } else {
            imgHeight = originalHeight * widthRatio;
            imgWidth = originalWidth * widthRatio;
         }
      }

      return { height: imgHeight, width: imgWidth };
   }

   render() {
      const { width, height } = this.props;
      const containerStyles = {
         width: `${width}px`,
         height: `${height}px`
      };

      return <div style={containerStyles} className="photo-viewer-container" id="pg-photo-container" />;
   }
}
