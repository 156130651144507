import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Consts
const server = process.env.REACT_APP_SERVER_URL;
const APP_JSON = 'application/json';

const startApplication = async () => {
   let username = '';
   let personNameSurname = '';
   let sessionID = '';
   let role = '';
   let isAuthenticated = false;
   try {
      // Controllo se è gia presente una sessione per questo utente
      const response = await fetch(`${server}/api/alfresco/auth/session`, {
         method: 'GET',
         credentials: 'include',
         headers: { Accept: APP_JSON, 'Content-Type': APP_JSON }
      });
      const result = await response.json();
      username = result.username;
      sessionID = result.sessionID;
      if (response.ok && sessionID && sessionID !== '') isAuthenticated = true;
      else isAuthenticated = false;

      if (isAuthenticated) {
         const roleResponse = await fetch(`${server}/api/user/role`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ username: username })
         });
         const roleResult = await roleResponse.json();
         role = roleResult.role_id;
      }

      if (isAuthenticated) {
         const foundName = await fetch(`${server}/api/user/name`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ username: username })
         });
         const nameResult = await foundName.json();
         personNameSurname = nameResult.name;
      }
   } catch (error) {
      console.log(error);
   }

   ReactDOM.render(
      <Router>
         <App
            server={server}
            username={username}
            personNameSurname={personNameSurname}
            sessionID={sessionID}
            isAuthenticated={isAuthenticated}
            role={role}
         />
      </Router>,
      document.getElementById('root')
   );

   // If you want your app to work offline and load faster, you can change
   // unregister() to register() below. Note this comes with some pitfalls.
   // Learn more about service workers: https://bit.ly/CRA-PWA
   // if (process.env.NODE_ENV === 'production') {
   //    serviceWorker.register();
   // } else serviceWorker.unregister();
   serviceWorker.unregister();
};

if (process.env.NODE_ENV === 'production') {
   if (!window.console) window.console = {};
   var methods = ['log', 'debug', 'warn', 'info'];
   for (var i = 0; i < methods.length; i++) console[methods[i]] = function() {};
}
startApplication();
