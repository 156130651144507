/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, /* useLayoutEffect, */ useEffect } from 'react';
import { Card, Badge } from 'react-bootstrap';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileViewer from '../core/file-viewer/file-viewer';

const APP_JSON = 'application/json';
const loadingMessage = 'Caricamento preview...';
// FIXME:

export default function DocumentInfo(props) {
   // Props
   const { username, elementID, nodeInfo, server, elementPath } = props;
   // Dati utilizzati per gestire download del file
   const [documentURL, setdocumentURL] = useState('/');
   const [documentType, setdocumentType] = useState(null);
   // Messaggio e indicatore di attesa durante il caricamento
   const [message, setMessage] = useState(null);
   const [loading, isLoading] = useState(false);

   const version = ` ${nodeInfo?.properties['cm:versionLabel'] ?? '0.0'}`;

   // TODO:
   // ultima versione per la quale il documento è stato valutato
   // let oldVersion = '';

   // Reperimento dati
   // Siccome non sono indicate 'dipendenze' questo effetto viene eseguito dopo ogni render del componente
   // https://www.robinwieruch.de/react-hooks-fetch-data
   useEffect(() => {
      if (!username || !elementID) return;
      fetchNodeInfo();
      if (nodeInfo) fetchNodeContent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [elementID, nodeInfo, props, server, username]);

   function fetchNodeContent() {
      const { mimeType } = nodeInfo.content;
      (async () => {
         isLoading(true);
         try {
            const response = await fetch(`${server}/api/alfresco/nodes/node/content`, {
               method: 'POST',
               credentials: 'include',
               headers: { 'Content-Type': APP_JSON, Accept: '*/*' },
               body: JSON.stringify({ elementID, elementPath, nodeInfo })
            });
            const result = await response.arrayBuffer();
            if (response.ok) {
               const url = URL.createObjectURL(new Blob([result], { mimeType }));
               let type = mimeType.substring(mimeType.lastIndexOf('/') + 1);
               if (type === 'vnd.openxmlformats-officedocument.wordprocessingml.document') type = 'docx';
               if (type === 'msword') type = 'docx'; // FIXME: non funziona per .doc

               setdocumentURL(url);
               setdocumentType(type);
            } else {
               setMessage(`Errore nel reperimento del documento : ${response.status} -> ${response.statusText}`);
            }
         } catch (err) {
            console.log(err);
            setMessage(`Errore nel reperimento del documento : ${err}`);
         }
         isLoading(false);
      })();
   }

   function fetchNodeInfo() {
      (async () => {
         const response = await fetch(`${server}/api/user_doc/find`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': APP_JSON },
            body: JSON.stringify({ username, elementID })
         });
         const result = await response.json();
         //console.log('SelfEvaluation.nodeInfo', nodeInfo);
      })();
   }

   let component = null;
   if (message) component = <Card.Text>{message}</Card.Text>;
   else if (documentType && documentURL) component = <FileViewer fileType={documentType} filePath={documentURL} />;

   return (
      <>
         <Card className="kt-section__content no-margin">
            <Card.Header as="h4">
               {nodeInfo?.name ?? 'Nome Elemento'}{' '}
               <Badge variant="primary"> {loading ? loadingMessage : version} </Badge>
            </Card.Header>
            <Card.Body>
               {/*<Card.Subtitle className="mb-2 text-muted">{elementID || 'ID Elemento'}</Card.Subtitle>*/}
               {loading ? <Card.Text>{loadingMessage}</Card.Text> : component}
            </Card.Body>
         </Card>

         <Card className="kt-section__content">
            <Card.Body>
               <BottomNavigation showLabels>
                  {/*
                  <BottomNavigationAction
                     target="_blank"
                     href={`http://192.168.4.201:8085/share/page/context/shared/document-details?nodeRef=workspace://SpacesStore/${elementID}`}
                     label="SHARE"
                     icon={<GetAppIcon />}
                     disabled={documentURL === '/'}
                  />
                  */}
                  <BottomNavigationAction
                     target="_blank"
                     href={documentURL}
                     label="DOWNLOAD"
                     icon={<GetAppIcon />}
                     disabled={documentURL === '/'}
                     download={nodeInfo?.name ?? nodeInfo?.name ?? 'download'}
                  />
               </BottomNavigation>
            </Card.Body>
         </Card>
      </>
   );
}
